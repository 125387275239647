.portfolio {
  padding: 5rem 0 2.5rem;
  &-list {
    &__item {
      text-decoration: none !important;
      display: block;
      margin-bottom: 2.5rem;
      &:hover {
        .portfolio-list__image {
          opacity: 0.75; }
        .portfolio-list__name {
          color: $primary; } } }
    &__image {
      margin-bottom: 2rem;
      height: 200px;
      width: 100%;
      object-fit: cover;
      display: block;
      transition: opacity .25s ease; }
    &__name {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #0A0F24;
      margin-bottom: 12px; }
    &__description {
      font-size: 16px;
      line-height: 28px;
      color: #0A0F24;
      opacity: 0.9;
      margin: 12px 0px; } } }

.portfolio-list--large .portfolio-list__image {
    height: 300px;
    @include r(767) {
      height: 200px; } }

.portfolio-about {
  padding-bottom: 3rem;
  .video-js {
    min-height: 300px;
    height: 19.125rem;
    @include r(767) {
      height: 200px;
      min-height: 200px; } }
  .vjs-loaded .vjs-big-play-button .vjs-control-text {
    font-size: 1.125rem; } }

.project-card__image {
  height: 27.25rem;
  width: 100%;
  object-fit: cover;
  margin-bottom: 2.2rem; }
