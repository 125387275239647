.page-title {
  &-widget {
    margin-bottom: 3rem; } }

.video-js {
  width: 100%; }


.video-block {
  .video-js {
    min-height: 39.375rem;
    height: 39.375rem;
    @include r(767) {
      height: 200px;
      min-height: 200px; } } }

.vjs-poster {
  background-size: cover; }


.vjs-loaded .vjs-big-play-button .vjs-control-text {
  border: 0;
  min-width: 200px;
  display: block;
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  padding: 0;
  position: absolute;
  clip: auto;
  height: auto;
  width: auto;
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 120%;
  color: #FFFFFF; }

.contacts-info {
  position: relative;
  z-index: 1;
  &::after {
    content: '';
    background: url('../img/partials/content-block__bg.svg') 70% center / contain no-repeat;
    display: block;
    position: absolute;
    width: 100%;
    height: 13rem;
    left: 0;
    top: 50%;
    transform: translate(-25%, -50%);
    z-index: -1; } }

.btn-dark:hover {
    color: #fff;
    background-color: #484848;
    border-color: #484848; }

.block__title--underline {
  transition: color .3s ease;
  &::after {
    opacity: 1;
    transform: translateY(0);
    transition: all .2s ease; }
  &:hover {
    text-decoration: none;
    &::after {
      opacity: 0;
      transform: translateY(10px); } } }

a {
  transition: color .2s ease; }


.white-popup {
  background: #fff;
  padding: 70px 70px 100px;
  max-width: 685px;
  margin: 0 auto;
  position: relative; }

.footer-socials__item, .contacts-info__social a {
  svg {
    transition: fill .2s ease; }
  &:hover {
    svg {
      fill: $primary; } } }

.footer-author img {
  transition: transform .2s ease;
  &:hover {
    transform: scale(1.2); } }
