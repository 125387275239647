.hero {
  padding-bottom: 8.2rem;
  padding-top: 4.3rem;
  &__title {
    font-weight: 900;
    font-size: 4rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: $primary; }
  &-image {
    text-align: center;
    position: relative;
    &__bg {
      max-width: 100%;
      svg {
        max-width: 100%; } }

    &__lead {
      max-width: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      pointer-events: none; }
    // width: 135%;
    // margin-left: -18%;
    // margin-right: -18%;
    // position: relative
    // z-index: -1
    // margin-bottom: -44%;
 } }    // margin-top: -4%;

