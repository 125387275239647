.advantages {
  padding: 8rem 0;
  overflow: hidden;
  &__list {
    height: 100%;
    position: relative; }
  &__item {
    position: absolute;
    left: 0;
    top: 1000px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: $primary;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      .advantages__name {
        opacity: 1; }
      img {
        box-shadow: 0px 16px 24px rgba(242, 47, 21, 0.3); } }
    &:nth-child(1) {
      left: 3.8%;
      top: 8.5%;
      .advantages__name {
        order: -1; } }
    &:nth-child(2) {
      left: 13.2%;
      top: 71%; }
    &:nth-child(3) {
      left: 22%;
      top: 9%;
      .advantages__name {
        order: -1; } }
    &:nth-child(4) {
      top: -15%;
      left: 42%;
      .advantages__name {
        order: -1; } }
    &:nth-child(5) {
      top: 66%;
      left: 40%; }
    &:nth-child(6) {
      top: 58%;
      left: 59%; }
    &:nth-child(7) {
      top: -3%;
      left: 63%;
      .advantages__name {
        order: -1; } }



    img {
      transition: box-shadow .2s ease;
      border-radius: 50%; } }
  &__name {
    opacity: 0;
    transition: opacity .2s ease;
    min-width: 110px; }
  &-content {
    max-width: 570px;
    &__title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 2.5rem; }
    &__description {
      line-height: 1.6; } } }
