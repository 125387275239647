.icon-arrow {
  width: 0.57em;
  height: 1em;
  fill: initial;
}
.icon-dialog {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-fb {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-insta {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-tg {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-wa {
  width: 1em;
  height: 1em;
  fill: initial;
}
