.services {
  padding: 2.5rem 0 4rem;
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(280px, auto);
    // background: #DCDCDC
    grid-gap: 0;
 }    // border: 1px solid #DCDCDC

  &__item {
    background: #fff;
    padding: 32px;
    box-shadow: none;
    transition: all .15s ease;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    text-decoration: none !important;
    border: 1px solid #dcdcdc;
    margin-right: -1px;
    margin-bottom: -1px;
    &:hover {
      box-shadow: 0 0 0 4px #F22F15, 0px 24px 24px rgba(242, 47, 21, 0.12);
      z-index: 1 !important;
      .services__image {
        filter: grayscale(0%) !important;
        opacity: 1 !important; } }
    &--large {
      grid-column-start: 1;
      grid-column-end: 3; } }
  &__image {
    max-width: 58px;
    max-height: 58px;
    position: absolute;
    right: 33px;
    top: 37px;
    filter: grayscale(100%);
    opacity: 0.25;
    transition: all .35s ease; }
  &__name {
    margin-top: auto;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #0A0F24;
    padding-right: 2rem; }
  &-lead {
    &__num {
      font-weight: 800;
      font-size: 96px;
      line-height: 120px;
      color: #F22F15; }
    &__text {
      font-size: 16px;
      line-height: 28px;
      color: #0A0F24;
      opacity: 0.9; } } }

.about-service {
  padding-bottom: 2.5em; }

.service-work-widget {
  padding: 2rem 0; }
.service-work__title {
  @include text25;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem; }
