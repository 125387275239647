.news {
  padding: 3.8rem 0 2.5em;
  &-list {
    &__item {
      text-decoration: none !important;
      margin-bottom: 2.6rem;
      display: block;
      &:hover {
        .news-list__picture {
          opacity: 0.75; }
        .news-list__title {
          color: $primary; } } }
    &__picture {
      height: 128px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 1.5rem;
      transition: opacity .25s ease; }
    &__title {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #0A0F24;
      margin-bottom: 0.5rem; }
    &__date {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #0A0F24;
      opacity: 0.9; } } }

.news-content {
  background: url('../img/partials/content-block__bg.svg') center center/40% 40% no-repeat; }
