.header {
  padding: 3.6rem 0;
  &-logo {
    max-width: 5.0625rem;
    display: inline-block;
    &__image {
      max-width: 100%;
      max-height: 100%; } }
  &-nav {
    margin-left: 2.5rem;
    &__item {
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 2.1rem;
      &:last-child {
        margin-right: 0; } } }
  &__lead {
    display: flex;
    flex-wrap: wrap; }
  &__second {
    display: flex;
    flex-wrap: wrap; }

  &-callme {
    font-weight: bold;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    svg {
      margin-right: 0.75rem;
      width: 1.25rem;
      minwidth: 1.25rem;
      height: 1.25rem; } }
  &-lang {
    display: inline-flex;
    margin-left: 3rem;
    &__item {
      font-weight: bold;
      text-transform: uppercase;
      &::after {
        content: '/';
        color: $dark; }
      &:last-child {
        &::after {
          content: none; } }

      &_active {
        color: $primary; } } } }
