html, :root {
	font-size: 16px;
	transition: font-size .15s ease;
	// +rmin(992)
	// 	font-size: 12px
	// +rmin(1199)
	// 	font-size: 14px
	// +rmin(1300)
	// 	font-size: 15px
	// +rmin(1360)
	// 	font-size: 16px
	// +rmin(1700)
	// 	font-size: 21px
	// +rmin(2300)
 }	// 	font-size: 28px

html, body {
	height: 100%; }

body {
	background: #fff;
	font-size: 1rem;
	font-family: $font-primary;
	display: flex;
	flex-direction: column;
	line-height: 120%;
	-webkit-font-smoothing: antialiased;
	user-select: none;
	// responsive
 }	// +css-lock(16, 50, 600, 1000)

.btn {
	text-transform: uppercase; }


.main {
	padding: 20px 0 40px;
	flex-grow: 1; }


.wave-content {
	position: relative;
	&::before, &::after {
		content: '';
		width: 34.5rem;
		height: 16.75rem;
		position: absolute;
		display: block;
		right: calc(100% + 2rem);
		top: 50%;
		transform: translateY(-50%);
		background: url('../img/partials/wave.svg') center / contain no-repeat;
		z-index: -1; }
	&::after {
		left: calc(100% + 2rem); } }


.block__title {
	font-weight: bold;
	font-size: 40px;
	line-height: 48px;
	color: #181818;
	display: inline-block;
	position: relative;
	margin-bottom: 2.9rem;
	&--underline {
		&::after {
			content: '';
			position: absolute;
			bottom: 7px;
			left: 0;
			width: 100%;
			height: 8px;
			background: $primary;
			z-index: -1; } } }

.content-block {
	margin: 5.2rem 0 5.2rem; }


.form-control {
	border: 0;
	border-bottom: 2px solid #BFC6DE;
	outline: none !important;
	box-shadow: none !important;
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 1.5rem;
	color: #000;
	&:focus {
		border-color: $primary; } }

.pristine-error {
	font-size: 12px;
	margin-top: -1rem;
	color: $primary; }


.contacts-info {
	&__item {
		font-size: 1.375rem;
		line-height: 130%;
		display: flex;
		align-items: center; } }

.footer .container {
	overflow: hidden; }

.header {
	z-index: 1;
	position: relative; }


.header-logo {
	position: relative;
	z-index: 2; }

.mobile-nav {
	margin-left: 2rem;
	&__toggle {
		width: 25px;
		height: 25px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		position: relative;
		z-index: 2;
		span {
			width: 100%;
			height: 3px;
			margin-bottom: 6px;
			display: block;
			background: #000;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			transition: transform .3s ease;
			&:nth-child(1) {
				margin-bottom: 0;
				transform: translateY(calc(-50% - 5px)); }
			&:nth-child(2) {
				margin-bottom: 0;
				transform: translateY(calc(-50% + 5px)); } } }
	&__list {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100vh;
		background: #fff;
		z-index: 1;
		clip-path: inset(0 0 100% 0);
		pointer-events: none;
		transition: all .25s ease;
		padding: 100px 15px 50px;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		font-size: 24px; }
	&__item {
		margin-bottom: 2.5rem; } }

.menu-open {
	.mobile-nav__list {
		clip-path: inset(0 0 0 0);
		pointer-events: all; }
	.mobile-nav__toggle {
		span {
			&:nth-child(1) {
				transform: rotate(45deg); }
			&:nth-child(2) {
				transform: rotate(-45deg); } } } }
