.partners {
  padding: 5rem 0;
  &-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1px;
    background: #DCDCDC;
    border: 1px solid #DCDCDC;
    grid-auto-rows: minmax(160px, auto);
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      flex-grow: 1;
      transition: box-shadow .25s ease;
      &:hover {
        box-shadow: 0px 24px 24px rgba(242, 47, 21, 0.12);
        .partners-list__logotype {
          opacity: 1;
          filter: grayscale(0%); } } }
    &__logotype {
      width: 96px;
      height: 96px;
      object-fit: contain;
      display: block;
      opacity: 0.4;
      filter: grayscale(100%);
      transition: all .25s ease; } } }
