.experts {
  padding: 2rem 0;
  overflow: hidden;
  &__image {
    border-radius: 50%;
    overflow: hidden;
    width: 16.875rem;
    height: 16.875rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &-content {
    max-width: 570px;
    &__title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 2.5rem; }
    &__description {
      line-height: 1.6; } } }
