.request {
  padding: 4.3rem 0;
  &-form {
    margin: 2.5rem 0;
    &__title {
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      color: #181818; }

    &__policy {
      font-size: 14px;
      line-height: 24px;
      a {
        color: $primary; } } } }
