.about-content {
  padding: 1rem 0 5rem;
  position: relative;
  overflow: hidden;
  &__wrapper {
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: 39%;
      left: 59%;
      width: 59%;
      height: 15rem;
      background: url('../img/partials/content-block__bg.svg') center center / contain no-repeat; }
    &::after {
      content: '';
      position: absolute;
      left: -54%;
      bottom: 0%;
      width: 50%;
      height: 13rem;

      background: url('../img/partials/content-block__bg.svg') center center / contain no-repeat; } } }

