@include font('Montserrat', 'Montserrat-Regular', normal);
@include font('Montserrat', 'Montserrat-Bold', 700);
@include font('Montserrat', 'Montserrat-Black', 900);

$font-primary: 'Montserrat', sans-serif;

// only rem

@mixin text50 {
  font-size: 3.125rem;
  line-height: 120%; }

@mixin text40 {
  font-size: 2.5rem;
  line-height: 120%; }

@mixin text35 {
  font-size: 2.1875rem;
  line-height: 120%; }

@mixin text25 {
  font-size: 1.5625rem;
  line-height: 120%; }

@mixin text14 {
  font-size: 0.875rem;
  line-height: 120%; }


h1, .h1, .text50 {
  @include text50;
  font-weight: bold; }

.text40 {
  @include text40; }

.text35 {
  @include text35;
  font-weight: bold; }

.text25 {
  @include text25;
  font-weight: bold; }

.text14 {
  @include text14; }

.content {
  &-block {
    background: url('../img/partials/content-block__bg.svg') 70% center/auto 13.75rem no-repeat;
    &--large {
      background: url('../img/partials/content-block__bg.svg') 92% center/auto 13.75rem no-repeat; } }
  h2 {
    font-weight: bold;
    @include text40;
    color: #181818;
    margin-bottom: 2.5rem; }
  p {
    font-size: 1rem;
    line-height: 175%;
    color: #0A0F24;
    opacity: 0.9; } }

.content {
  img {
    margin: 2.5rem 0 4rem; } }
