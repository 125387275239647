.footer {
  background: rgba(10, 15, 36, 0.1);
  padding: 2rem 0;
  font-size: 14px;
  line-height: 28px;
  color: #0A0F24;
  opacity: 0.9;
  &-logo {
    margin-top: 0.5rem;
    max-width: 8.1875rem;
    display: inline-block;
    &__image {
      max-width: 100%;
      max-height: 100%; } }
  &-contacts {
    &__item {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      color: #0A0F24;
      display: block; } }
  &-links {
    columns: 3;
    margin-right: -6.4rem;
    &__item {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      color: #0A0F24;
      display: block;
      flex-grow: 1; } }
  &-socials {
    &__item {
      text-decoration: none !important;
      margin-right: 0.5rem;
      svg {
        width: 1.8rem;
        height: 1.8rem; } } } }
