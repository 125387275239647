.studio {
  padding: 3.5rem 0 4rem;
  overflow: hidden;
  &-slider {
    overflow: visible;
    .swiper-slide {
      opacity: .3;
      transition: opacity .3s ease; }
    .swiper-slide-active {
      opacity: 1 !important;
      + .swiper-slide {
        opacity: 1 !important;
        + .swiper-slide {
          opacity: 1 !important; } } }
    &__arrows {
      &-next {
        transition: transform .25s ease;
        display: inline-block;
        &:hover {
          transform: scale(1.15); } }
      &-prev {
        transform: rotate(180deg);
        margin-right: 2.3rem;
        display: inline-block;
        transition: transform .25s ease;
        &:hover {
          transform: scale(1.15) rotate(180deg); } } } }
  &-photo {
    &__image {
      margin-bottom: 2rem;
      height: 200px;
      width: 100%;
      object-fit: cover;
      display: block; }
    &__name {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #0A0F24;
      margin-bottom: 12px; }
    &__description {
      font-size: 16px;
      line-height: 28px;
      color: #0A0F24;
      opacity: 0.9;
      margin: 12px 0px; } }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.9rem;
    .block__title {
      margin-bottom: 0; } } }


.icon-arrow {
    width: 1.5rem;
    height: 2.8rem;
    fill: #000; }

.video-block {
  margin-bottom: 3rem; }

.vjs-poster {
  position: absolute;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.5); } }
