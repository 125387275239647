// +r(size)
// +r(991)

@include r(1199) {
  .header-nav__item {
    margin-right: 0.9rem; }
  .header-nav {
    margin-left: 1.5rem; }
  .hero__title {
    font-size: 3.4rem; }
  .hero {
    padding-bottom: 4.2rem;
    padding-top: 1.3rem; }
  .advantages {
    padding: 7rem 0 5rem; }
  .services-lead__num {
    font-size: 4.8rem; }
  .services__item {
    padding: 22px; }

  .header {
    padding: 2.6rem 0; }

  .footer-links {
    margin-right: 0; } }

@include r(991) {
  .hero__title {
    font-size: 2.5rem; }
  .header-lang {
    margin-left: 2rem; }
  .experts__image {
    min-width: 12.875rem;
    width: 12.875rem;
    height: 12.875rem; }

  .hero {
    padding-bottom: 2.2rem; }

  .advantages__list {
    min-height: 200px; }

  .wave-content::after, .wave-content::before {
    top: 100%;
    transform: none; }

  .services__list {
    grid-template-columns: repeat(2,1fr); }

  .partners-list {
    grid-template-columns: repeat(3,1fr); } }


@include r(767) {
  .hero__title {
    font-size: 38px;
    text-align: center; }
  .services__list {
    grid-template-columns: repeat(1,1fr); }
  .services__item--large {
    grid-column-start: 1;
    grid-column-end: 1; }
  .services__list {
    grid-auto-rows: minmax(220px,auto); }
  .services-lead__text {
    margin-bottom: 1rem; }

  .partners-list {
    grid-template-columns: repeat(2,1fr); }

  .footer-links {
    columns: 2; }

  .header {
    padding: 1.3rem 0; }
  .header-lang {
    margin-left: 2rem;
    font-size: 14px; }
  .hero .btn {
    margin-left: auto;
    margin-right: auto;
    display: block; }

  .experts__image {
    min-width: 167px;
    width: 167px;
    height: 167px;
    margin-bottom: 1.5rem; }

  .experts-content__title, .advantages-content__title, .block__title, .content h2, .request-form__title, .page-title {
    font-size: 24px;
    line-height: 28px; }

  .block__title--underline::after {
    bottom: 2px;
    height: 5px; }

  .breadcrumb-item {
    font-size: 14px; }
  .content img {
    max-width: 100%; }

  .content-block {
    margin: 2.2rem 0; }
  .page-title-widget {
    margin-bottom: 2rem; }
  .video-block .video-js, .portfolio-about .video-js {
    min-height: 230px; }
  .project-card__image {
    height: 8.25rem; }
  .white-popup {
    padding: 40px 30px 30px; }

  .advantages {
    padding: 2rem 0 3rem; }
  .advantages__item {
    font-size: 10px;
    line-height: 12px; }
  .advantages__item:nth-child(1) {
    left: -0.2%;
    top: -0.5%; }

  .advantages__item:nth-child(3) {
    left: 18%;
    top: 12%; }

  .advantages__item:nth-child(6) {
    top: 57%;
    left: 72%; }
  .advantages__name {
    display: none !important; }
  .icon-arrow {
    width: 0.9rem;
    height: 1.8rem; }
  .studio-slider__arrows-prev {
    margin-right: 1.3rem; }
  .studio {
    padding: 2.5rem 0 2rem; }
  .content-block {
    margin: 3.2rem 0; }
  .news {
    padding: 2.8rem 0 2.5em; }
  .portfolio {
    padding: 3rem 0 2.5rem; }
  .partners {
    padding: 2rem 0; }

  .request {
    padding: 2.3rem 0; } }
